@import "./override.scss";
@import "../../node_modules/font-awesome/css/font-awesome.css";
@import "./font.scss";

body{   
    font-weight: 400;
    color: #1b2a4e;
    line-height: inherit;
    margin: 0;
    text-align: left;
    font-family:Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;  

    .bg-blu{
        background-color: white;
        color: $white;
    
    .navbar-brand{
        display:inline-block;font-size:1rem;
        line-height:inherit;margin-right:1rem;
        padding-bottom:.3125rem;
        padding-top:.3125rem;
        white-space:nowrap;
        font-size: 1.25rem;
        color: $white;
        
        .aresTitle{
            font-weight: bolder;   
        }     
        
    }
    .circle-container{
        width: 2rem;
        height: 2rem;
        border-radius: 100px;
        background:#1b2a4e;
        position: relative;
        right: 1.5rem;
        .circle-element{
            width: 100%;
            text-align: center;
            color: white;
            line-height: 2rem;
            font-size: 0.9rem;
        }
    }
    
    .dropLogin{
        position: absolute;
        inset: 0px 0px auto auto;
        margin: 2px 25px 11px 0px;
        transform: translate3d(-0.5px, 34px, 0px);
        border-radius: 3px;
        padding: 8.5px 10px 4.5px 10px;

        i{
            color: #d9e2ef; 
            display: inline-block; 
            margin-left: -10px; 
            margin-right: 10px; 
            text-align: center; 
            width: 20px;
            padding-bottom: 7px;
        }
        
    }
}
 
    #main-content{ 
        min-height: 100vh;
        padding-top: 60px;

        
        .card{
            width: 100%;
 
              
        a.nounderline {text-decoration: none; }
        
        .card-title{
            font-weight: 300 !important;
            line-height: 1.2; 
    
        }
        .card-text{
            font-weight: 400;
            font-size: 1rem;
        }
        
        }
        .card:hover{          
            background-color: rgb(243, 243, 243);    
        }  
        
    } 

    a {
        color: #1b2a4d;
    }
}